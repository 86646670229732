import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import NavBar from './nav_bar';
import ControlledAccordions from './top_accordion';
import OrderSearch from './order_search';
import OrderTable from './order_table';
import WorldWiseOrderTable from './worldwise_order_table';
import CNC from './foamCutter';
import Slitter from './foamSlitter';
import Popup from 'reactjs-popup';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import texasSkus from '../data/texasSkus.json';
import OnTruck from './onTruck';


function ProductionTablet(props) {
    const [order, changeOrder] = useState(null);
    const [type, changeType] = useState("");
    const [locale, changeLocale] = useState("en");
    const [refresh, updateRefresh] = useState(0);
    const [employees, updateEmployees] = useState([]);
    const [ShipmentNbr, setShipmentNbr] = useState(null);
    const [wwItems, setWWItems] = useState([]);
    const [wwTotal, setWWTotal] = useState(0);
    const [openConfirmWindow, setOpenConfirmWindow] = useState(false);
    const [loading, setLoading] = useState(false);

 
    const os = require('os');
    let api = "furhaven-production.com/v1";
    // if(os.hostname().indexOf("local") > -1 || os.hostname().indexOf("dev.") > -1) {
    //     api = "dev." + api;
    // }
    if(os.hostname().indexOf("local") > -1 || os.hostname().indexOf("leas.") > -1 || os.hostname().indexOf("10.1") > -1){
        api = "leas." + api;
    }
    const baseApiUrl = "https://api." + api;


    useEffect(()=> {
    }, [])

    const lang = {
        "en": {
            "leaveBuildWarning":"Warning: There are items in your current build. Exiting will remove them. Do you still wish to continue?",
            "cancel":"Cancel",
            "accept":"Accept"
        },
        "es": {
            "leaveBuildWarning":"Advertencia: Hay elementos en tu compilación actual. Salir los eliminará. ¿Todavía desea continuar?",
            "cancel":"Cancelar",
            "accept":"Aceptar",
        }
    }

    function goBack(confirmGoBack) {
        if(wwTotal > 0 && confirmGoBack !== true){
            setOpenConfirmWindow(true);
        }
        else{
            changeOrder(null);
            changeType("");
            props.resetter();
        }
    }

    function refreshToken() {
        updateRefresh(refresh + 1);
    }


    function setOrder(res) {
        let out = {
            "Customer": "",
            "CustomerOrderNbr": "",
            "OrderNbr": "",
            "Date":"",
            "Items": [],
            "Total": 0,
            "Type": "",
        };
        for(let i = 0; i < res.length; i++) {
            out["Customer"] += res[i].Customer;
            out["CustomerOrderNbr"] += res[i].CustomerOrderNbr;
            out["OrderNbr"] += res[i].OrderNbr;
            out["Date"] = res[i].Date;
            out["Type"] = res[i].Type;
            if(res[i].ShipmentNbr) {
                setShipmentNbr(res[i].ShipmentNbr);
            }
            else {
                setShipmentNbr(null);
            }
            if(i < res.length - 1) {
                out["Customer"] += ", ";
                out["CustomerOrderNbr"] += ", ";
                out["OrderNbr"] += ", ";
            }
            for(let j = 0; j < res[i].Items.length; j++) {
                out["Items"].push(res[i].Items[j]);
                out["Total"] += res[i].Items[j].Qty;
            }
        }
        changeOrder(out);
    }

    function setType(res) {
        changeType(res);
    }

    function resetOrder() {
        changeOrder(null);
    }

    function updateLocale() {
        if(locale === "en") {
            changeLocale("es");
        }
        else {
            changeLocale("en");
        }
    }

    return (
        <div>
            <Popup 
                style = {{margin:"auto"}} 
                open = {openConfirmWindow} 
                onClose = {() => setOpenConfirmWindow(false)}
            >
                <Box 
                    display = "flex" 
                    flexDirection = "column" 
                    style = {{ padding: "5px", border: "1px solid black", background: "white", width: "50%", margin: "auto"}}
                >
                    {lang[locale].leaveBuildWarning}
                    <Box 
                        display = "flex" 
                        style = {{margin: "auto", width: "70%", marginTop: "15px"}}
                    >
                        <Button 
                            style = {{
                                borderTopLeftRadius: "0", 
                                borderBottomLeftRadius: "0", 
                                marginRight:"auto"
                            }} 
                            variant = "contained" 
                            color = "warning" 
                            disabled = {loading} 
                            onClick = {() => setOpenConfirmWindow(false)}
                        >
                            {lang[locale].cancel}
                        </Button>
                        <Button 
                            style = {{
                                borderTopLeftRadius: "0", 
                                borderBottomLeftRadius: "0", 
                                marginLeft: "auto"
                            }} 
                            variant = "contained" 
                            color = "primary" 
                            disabled = {loading} 
                            onClick = {() => goBack(true)}
                        >
                            {lang[locale].accept}
                            {loading ? 
                                <CircularProgress 
                                    size = {30} 
                                    style = {{ color:'black', position: 'absolute' }}>
                                </CircularProgress> 
                            : null }
                        </Button>
                    </Box>
                </Box>
            </Popup>
            <NavBar 
                wwTotal = {wwTotal}
                resetter = {goBack} 
                name = {props.wh.name + " " + props.group.name} 
                color = {props.group.color} 
                locale = {locale} setter={updateLocale}
            />
            {(props.wh.id === "PA" && (props.group.line <= 11 || props.group.line > 20)) ? 
                <Box 
                    style = {{ marginTop: "10px" }} 
                    display = "flex" 
                    flexDirection = "column">
                    <div style = {{margin: "auto"}}>
                        <ControlledAccordions 
                            base = {baseApiUrl} 
                            employees = {employees} 
                            update = {updateEmployees} 
                            refresh = {refresh} 
                            wh = {props.wh.id} 
                            locale = {locale} 
                            group = {props.group.line} 
                            wwTotal = {wwTotal}
                        />
                    </div>
                    <div style = {{ margin: "auto", marginTop: "30px", width: "80%" }}>
                        { !order ? 
                            <OrderSearch 
                                base = {baseApiUrl} 
                                wh = {props.wh} 
                                group = {props.group} 
                                locale = {locale} 
                                typeSetter = {setType} 
                                setter = {setOrder} 
                                color = {props.group.color} 
                            /> 
                        : 
                            <OrderTable 
                                base = {baseApiUrl} 
                                wh = {props.wh.id} 
                                branch = {props.wh.branch} 
                                group = {props.group.line} 
                                refresh = {refreshToken} 
                                locale = {locale} 
                                employees = {employees} 
                                totalQty = {order.Total} 
                                type = {type} 
                                orderNbr = {order.OrderNbr} 
                                shipmentNbr = {ShipmentNbr} 
                                orderDate = {order.Date} 
                                location = {order.Location} 
                                customer = {order.Customer} 
                                order = {order.Items} 
                                resetter = {resetOrder} 
                            />
                        }
                    </div>
                </Box>
            : (props.wh.id === "TX") ?
                <Box style = {{marginTop: "10px"}} display = "flex" flexDirection = "column">
                    <div style = {{ margin: "auto" }}>
                        <ControlledAccordions 
                            base = {baseApiUrl} 
                            employees = {employees} 
                            update = {updateEmployees} 
                            refresh = {refresh} 
                            wh = {props.wh.id} 
                            locale = {locale} 
                            group = {props.group.line} 
                            wwTotal = {wwTotal}/>
                    </div>
                    <div style = {{margin: "auto", marginTop: "30px", width: "80%"}}>
                        <WorldWiseOrderTable
                            wwTotal = {wwTotal}
                            setWWTotal = {setWWTotal}
                            wwItems = {wwItems}
                            setWWItems = {setWWItems}
                            base = {baseApiUrl}
                            resetter = {resetOrder} 
                            wh = {props.wh.id} 
                            group = {props.group} 
                            refresh = {refreshToken} 
                            locale = {locale} 
                            employees = {employees} 
                        />
                    </div>
                </Box>
            : (props.wh.id === "SHIP") ?
                <OnTruck 
                    base = {baseApiUrl} 
                    locale = {locale} 
                    line = {props.group.line}
                    carrier = {props.group.name}
                />
            :
                <Box display = "flex" flexDirection = "column" style = {{ marginTop: "10px" }}>
                    {
                        (props.group.line === 14) || (props.wh.id === "PA" && props.group.line === 12)  ?
                            <CNC base = {baseApiUrl} locale = {locale} line = {props.group.line}/> 
                        : <Slitter base = {baseApiUrl} locale ={locale} line = {props.group.line} /> 
                    }
                </Box>
            }
        </div>
    );
};

export default ProductionTablet;
