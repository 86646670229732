import React, {useEffect, useState} from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function OnTruck(props) {

    const [truckNumber, setTruckNumber] = useState('');
    const [inputTruckNumber, setInputTruckNumber] = useState('');
    const [tracking, setTracking] = useState(null);
    const [loading, setLoading] = useState(false);
    const [trackingArray, setTrackingArray] = useState([]);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [trackingLength, setTrackingLength] = useState(0);

    const carrier = props.carrier;

    const os = require('os');
    let api = "furhaven-production.com/v1";
    if(os.hostname().indexOf("local") > -1 || os.hostname().indexOf("leas.") > -1 || os.hostname().indexOf("10.1") > -1){
        api = "leas." + api;
    }
    const baseApiUrl = "https://api." + api;

    const lang = {
        "en": {
            "enter": "Enter",
            "submit": "Submit",
            "tracking" : "Tracking Number",
            "date" : "Date",
            "delete": "Delete",
            "action": "Action",
            "truck": "Truck Number"
        },
        "es": {
            "submit":"Enviar",
        }
    };

    const handleTruckChange = (e) => {
        const value = e.target.value;
        setInputTruckNumber(value);

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        const timeout = setTimeout(() => {
            console.log(carrier);
            if ((carrier === 'FedEx' && (value.toLowerCase().includes("usps") || value.toLowerCase().includes("ups"))) || 
                (carrier === 'UPS' && value.toLowerCase().includes("usps")) ||
                (carrier === 'USPS' && value.toLowerCase().includes("ups")) ||
                (value.length === 12 || value.length === 18 || value.length === 22 || value.length === 30)) {
                window.alert("Truck Number invalid.");
                setInputTruckNumber('');
            } else {
                setTruckNumber(value);
            }
        }, 500); 

        setDebounceTimeout(timeout);
    };

    function reset() {
        setTracking(null);
        document.querySelectorAll("input").forEach((node) => {node.value = ""});
        setLoading(false);
    };

    useEffect(() => {
        if (tracking) {
            const delay = setTimeout(() => {
                addTrackingToArray();
            }, 500);
    
            return () => clearTimeout(delay);
        }
    }, [tracking]);

    useEffect(() => {
        const getTrackingLength = async () => {
            if (carrier === 'FedEx') setTrackingLength(12);
            if (carrier === 'USPS') setTrackingLength(22);
            if (carrier === 'UPS') setTrackingLength(18);
        };
        getTrackingLength();
    }, []);

    const checkDuplicateTracking = (trackNumber) => {
        const isDuplicate = trackingArray.some((item) => item.trackingNumber === trackNumber);
        return isDuplicate;
    };

    function addTrackingToArray() {
        setLoading(true);
        if ((carrier === 'FedEx' || carrier === 'UPS') && tracking.length !== trackingLength) {
            if (tracking.length % trackingLength === 0) {
                let splitTracking = [];
                for (let i = 0; i < tracking.length; i += trackingLength) {
                    splitTracking.push(tracking.slice(i, i + trackingLength));
                };
                const uniqueTracking = [... new Set(splitTracking)];
                for (let j = 0; j < uniqueTracking.length; j++) {
                    const duplicate = checkDuplicateTracking(uniqueTracking[j]);
                    if (duplicate) {
                        console.log("Duplicate tracking number detected."); 
                        continue;
                    };
                    insertScanInfo(uniqueTracking[j]).then(function() {
                        reset();
                    }).catch(function(err){
                        console.log(err);
                        reset();
                    })

                }
                reset();
                return;
            }
        } 
        const duplicate = checkDuplicateTracking(tracking);
        if (duplicate) {
            console.log("Duplicate tracking number detected."); 
            setLoading(false);
            reset();
            return;
        };
        insertScanInfo(tracking).then(function() {
            reset();
        }).catch(function(err){
            console.log(err);
            reset();
        })
        
    };

    function insertScanInfo(trackNumber) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const currentDatetime = new Date().toISOString(); 
                setTrackingArray((prevArray) => [
                    ...prevArray,
                    { trackingNumber: trackNumber, datetime: currentDatetime },
                ]);
                resolve();
            }, 500); 
        });
    };

    const handleRemoveTracking = (trackingNumber) => {
        setTrackingArray((prevArray) =>
            prevArray.filter((item) => item.trackingNumber !== trackingNumber)
        );
    };

    const submit = async () => {
        const url = baseApiUrl + "/operations/saveOnTruck";
        try {
            setLoading(true);
            const res = await axios.put(url, {truck_number: truckNumber, array_data: trackingArray},  {mode: 'no-cors'});
            if (res.status === 200) {
                setTrackingArray([]);
                setTruckNumber('');
                setInputTruckNumber('');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return ( 
        <Box  display = "flex" flexDirection = 'column' justifyContent = "center" alignItems = "center">
            { truckNumber ? (
                <Box display = "flex" flexDirection = "row" alignItems = "center" width = "80%">
                    <Box sx = {{ marginTop: "45px", flexShrink: 0 }}>
                        {'Truck Number: ' + truckNumber}
                    </Box>
                <Box sx = {{ marginTop: "45px", flexGrow: 1, display: "flex", justifyContent: "center" }}>
                    <Box sx = {{ width: "350px" }}>
                        <TextField
                            onChange = {(e) => setTracking(e.target.value)}
                            label = {lang[props.locale].tracking}
                            variant = "outlined"
                        />
                    </Box>
                </Box>
            </Box>
            ) : (
                <Box display = "flex" sx = {{ margin: "auto", marginTop: "45px" }}>
                    <TextField 
                        value = {inputTruckNumber}
                        onChange = {handleTruckChange} 
                        label = { lang[props.locale].truck } 
                        variant = "outlined">
                    </TextField>
                </Box>
            )}
            { trackingArray.length > 0 ? 
                <>
                    <TableContainer component = {Paper} style = {{ marginTop: "20px", width: "80%" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> {lang[props.locale].tracking} </TableCell>
                                    <TableCell> {lang[props.locale].date} </TableCell>
                                    <TableCell> {lang[props.locale].action} </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { trackingArray
                                    .slice()
                                    .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
                                    .map((item, index) => (
                                        <TableRow 
                                            key = {index}
                                            style = {{ background: item.trackingNumber.length === trackingLength ? 'white' : carrier === 'USPS' && item.trackingNumber.length === 30 ? 'white' : '#e5684e'}}
                                        >
                                            <TableCell > {item.trackingNumber} </TableCell>
                                            <TableCell> {item.datetime} </TableCell>
                                            <TableCell>
                                                <Button
                                                    variant = "contained"
                                                    color = "error"
                                                    onClick = {() => handleRemoveTracking(item.trackingNumber)}
                                                >
                                                    {lang[props.locale].delete}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button
                        disabled = {loading}
                        onClick = {() => submit()}
                        sx = {{ marginTop: '15px' }}
                        variant = "contained"
                        color = "success"
                    >
                        {lang[props.locale].submit}
                    </Button>
                </>
            : null}
        </Box>
       
    );

}

export default OnTruck;
